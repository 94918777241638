// This is the Basic Generator for Items, Factions, and People. Not used for Conversation or the game directly.

// {"name":"","appearance":"","desires":"","personality":"","weakness":"","background":"","hates":"","skills":[],"reason":"","relationship":""}

const general_response_schema = {
  "type": "object",
  "properties": {
    "name": {
      "type": "string",
      "description": "A descriptive name of the creature"
    },
    "creature_type": {
      "type": "string",
      "description": "The type of creature (e.g., Orc, Goblin, Dragon)"
    },
    "appearance": {
      "type": "string",
      "description": "A description of the creature's physical appearance",
      "maxLength": 50
    },
    "desires": {
      "type": "string",
      "description": "The creature's goals, motivations, or desires",
      "maxLength": 50
    },
    "personality": {
      "type": "string",
      "description": "A description of the creature's personality traits",
      "maxLength": 50
    },
    "weakness": {
      "type": "string",
      "description": "The creature's vulnerabilities or weaknesses",
      "maxLength": 50
    },
    "background": {
      "type": "string",
      "description": "The creature's backstory or history"
    },
    "hates": {
      "type": "string",
      "description": "Things the creature dislikes or hates",
      "maxLength": 50
    },
    "skills": {
      "type": "array",
      "items": {
        "type": "object",
        "properties": {
          "name": {
            "type": "string",
            "description": "The name of the skill"
          },
          "level": {
            "type": "integer",
            "description": "The creature's proficiency level in the skill"
          }
        },
        "required": [
          "name",
          "level"
        ]
      }
    },
    "reason": {
      "type": "string",
      "description": "Reason they want the character to succeed or fail",
      "maxLength": 50
    }
    ,
    "relationship": {
      "type": "string",
      "description": "Relationship to the main character, enemy, friend,",
      "maxLength": 50
    }
  },
  "required": [
    "name",
    "appearance",
    "hates"
  ]
}


 function NextImage(random){
  // console.log('move')
  var start_images = [
  'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Characters.jpg?x=157.328125&y=10.203125&width=421.65625&height=240.9375',
  'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Characters.jpg?x=98.328125&y=1.203125&width=421.65625&height=240.9375',
  'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Characters.jpg?x=149.328125&y=126.203125&width=421.65625&height=240.9375',
  'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Characters.jpg?x=70&y=90&width=341&height=194.84375']
  if(!random){
    this.imageIndex = this.imageIndex+1
  if(this.imageIndex > start_images.length-1){
    this.imageIndex = 0
  }
  // console.log(this.imageIndex)
  this.CharacterForm.src = start_images[this.imageIndex]
}
return start_images[Math.floor(Math.random()*start_images.length)]
  
}
async function fetchIdeasArray(prompt){
                // console.log(prompt);
                console.log('Running fetch Ideas Array')
                  // console.log(process.env.VUE_APP_GOOGLE_AI_API_KEY);
                  const prompt_data = {
                      "contents": [{parts:[{text:prompt}]
                      }]};
                  console.log(prompt_data)
                  const url = 'https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key='+process.env.VUE_APP_GOOGLE_AI_API_KEY
                  const call = await fetch(url, {
                    method: "POST", // *GET, POST, PUT, DELETE, etc.
                    // mode: "cors", // no-cors, *cors, same-origin
                    // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    // credentials: "same-origin", // include, *same-origin, omit
                    headers: {
                      "Content-Type": "application/json",
                      // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    // redirect: "follow", // manual, *follow, error
                    // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(prompt_data), // body data type must match "Content-Type" header
                  }).then(response => response.json())
                    .then(data => {
                      // console.log('Response:', data);
                      // Handle the API response here
                      // console.log(data.candidates[0].content.parts[0].text)
                      // console.log(data)
                      var content = data.candidates[0].content.parts[0].text
                      var array = []
                      // content = content.replace(/&quot;/ig,'"');
                      try{
                        array = JSON.parse(content)
                      }catch(error){
                        console.log(error)
                        console.log(content)
                        var clean = content.replace('```','')
                        clean = clean.replace('```','')
                        clean = clean.replace('json','')
                        array = JSON.parse(clean)
                        return array
                      }
                      return array
                    })
                    .catch(error => {
                      console.error('Error:', error);

                      // Handle errors here
                    });
                    return call
}
async function fetchIdeasObject(prompt){
                // console.log(prompt);
                console.log('Running fetchIdeasObject')
                  // console.log(process.env.VUE_APP_GOOGLE_AI_API_KEY);
                  const prompt_data = {
                      "contents": [{parts:[{text:prompt}]
                      }]};
                  console.log(prompt_data)
                  const url = 'https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key='+process.env.VUE_APP_GOOGLE_AI_API_KEY
                  const call = await fetch(url, {
                    method: "POST", // *GET, POST, PUT, DELETE, etc.
                    // mode: "cors", // no-cors, *cors, same-origin
                    // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    // credentials: "same-origin", // include, *same-origin, omit
                    headers: {
                      "Content-Type": "application/json",
                      // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    // redirect: "follow", // manual, *follow, error
                    // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(prompt_data), // body data type must match "Content-Type" header
                  }).then(response => response.json())
                    .then(data => {
                      // console.log('Response:', data);
                      // Handle the API response here
                      // console.log(data.candidates[0].content.parts[0].text)
                      // console.log(data)
                      var content = data.candidates[0].content.parts[0].text
                      var object = {}
                      // content = content.replace(/&quot;/ig,'"');
                      try{
                        object = JSON.parse(content)
                      }catch(error){
                        console.log(error)
                        console.log(content)
                        console.log('JSON is not working')
                        var clean = content.split("{")[1]
                        clean = clean.split("}")[0]
                        clean = '{'+clean+'}'
                        object = JSON.parse(clean)
                        return object
                      }
                      return object
                    })
                    .catch(error => {
                      console.error('Error:', error);

                      // Handle errors here
                    });
                    return call
}
async function fetchIdeasObjectSchema(prompt,schema){
                // console.log(prompt);
                console.log('Running fetchIdeasObject')
                  console.log('schema here')
                  console.log(schema)

                  const prompt_data = {
                      "contents": [{parts:[{text:prompt}]}],
                      "generationConfig":{
                          "responseMimeType":"application/json",
                          "responseSchema" : schema
                      }
                      };
                  console.log(JSON.stringify(prompt_data))
                  const url = 'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-pro-002:generateContent?key='+process.env.VUE_APP_GOOGLE_AI_API_KEY
                  const call = await fetch(url, {
                    method: "POST", // *GET, POST, PUT, DELETE, etc.
                    // mode: "cors", // no-cors, *cors, same-origin
                    // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    // credentials: "same-origin", // include, *same-origin, omit
                    headers: {
                      "Content-Type": "application/json",
                      // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    // redirect: "follow", // manual, *follow, error
                    // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(prompt_data), // body data type must match "Content-Type" header
                  }).then(response => response.json())
                    .then(data => {
                      // console.log('Response:', data);
                      // Handle the API response here
                      // console.log(data.candidates[0].content.parts[0].text)
                      // console.log(data)
                      var content = data.candidates[0].content.parts[0].text
                      var object = {}
                      // content = content.replace(/&quot;/ig,'"');
                      try{
                        object = JSON.parse(content)
                      }catch(error){
                        console.log(error)
                        console.log(content)
                        console.log('JSON is not working')
                        var clean = content.split("{")[1]
                        clean = clean.split("}")[0]
                        clean = '{'+clean+'}'
                        object = JSON.parse(clean)
                        return object
                      }
                      return object
                    })
                    .catch(error => {
                      console.error('Error:', error);

                      // Handle errors here
                    });
                    return call
}

function rumors (settings) {
    console.log('rumors')
    console.log(settings)

    var prompt = 'Your task is to create an encounter for a fantasy game. '+
    " \n Types can be social, creature, exploration, combat, or a puzzle. "
    if(typeof(settings) =='object' && 'context' in settings){
        prompt= prompt+" \n The rumor is told by the NPC in this text. "+settings['context'];
        console.log('conversation')
    }
    if(typeof(settings) =='object' && 'epic_motivation' in settings){
        prompt= prompt+'\n Explain how it might be tied to the characters main motivation.'+ 
        "\n Here is the characters main motivation, "+settings['epic_motivation'];
    }
     if(typeof(settings) =='object' && 'goals' in settings){
        prompt= prompt+'\n There should be a specific goal in this encounter that could be accomplished that would help with the characters main motivation.'+ 
        "\n Here is a list of available goals pick one and copy it in. "+JSON.stringify(settings['goals']);
    }
    if(typeof(settings) =='object' && 'active_npc' in settings){
        prompt= prompt+' Here is the npc the character is chatting with '+ settings['active_npc']
    }
    
    prompt = prompt+'"\nFill in the json object with the relevant text and respond. This is the format of the JSON object: {"rumor":"","type":"","location":"","twist":"","HowItsTiedToMotivation":"","goal":""} '+
    
    console.log(prompt)
    var response = fetchIdeasObject(prompt)
    // List of people interested in the outcome of this. Faction, NPC, Main Character, or Companion.
    if('interested' in settings){
        response['interested'] = settings['interested']
    }
    return response
}

function goals (settings) {
    console.log('goals')
    console.log(settings)

    var prompt = 'Your task is to create a list of goals for a character in a fantasy game. '+
    " \n The goals description should each be a short sentance that describes what the character must acomplish. "+
    " \n The goal types can be faction, item, npc, creature, info.  "+
    " \n The description should be related to the type, for example if its a creture it should be find, tame, or kill the creature.  "
    if(typeof(settings) =='object' && 'context' in settings){
        prompt= prompt+" \n The goal shoul text. "+settings['context'];
        console.log('conversation')
    }
    if(typeof(settings) =='object' && 'epic_motivation' in settings){
        prompt= prompt+'\n There should be a specific goal in this encounter that could be accomplished that would help with the characters main motivation.'+ 
        "\n Here is the characters main motivation, "+settings['epic_motivation'];
    }
    prompt = prompt+'"\nFill in the json object with the relevant text and respond. This is the format of the JSON object: [{"description":"","type":""} '+
    
    console.log(prompt)
    var response = fetchIdeasArray(prompt)
    return response
}

function locations (settings) {
    console.log('rumors')
    console.log(settings)
    const prompt = 'Your task is to create an encounter for a fantasy game that uses this format: {"rumor":"","type":"","location":"","twist":""} '+
    "Types can be social, creature, exploration, role playing, or a puzzle. "+
    "Fill in the json object with the relevant text and respond."
    var response = fetchIdeasObject(prompt)
    return response
}
async function npcs (settings) {
    console.log('npc')
    const classes = ['Alcehmist','Barbarian','Champion','Cleric','Druid','Fighter','Monk','Ranger','Rouge','Sorcer','Summoner','Witch','Wizard']
    var npc_class = classes[Math.floor(Math.random()*classes.length)];

     var fillers = {
      name:" The npc should have a first and last name. ",
      class: " The npc's class is "+npc_class+".",
      background: " The npc's background should have something to do with an occupation and their family. ",
      appearance: " The npc's appearance should include clothing, hair, how they hold themselves, and at least one unique physical feature. ",
      weakness: " The npc's weakness should be something a character could use against them such as an element like fire, water, or. ",
      desire:  " The npc's desire should be a concrete desire or tangible goal for the npc to work towards. For example: gold, acquisition of an item, the death of someone who wronged them. ",
      hates: " The npcs's hate should be specific and not related to their occupation.",
    }
    var prompt = 'Your task is to create an npc.'
    if('details' in settings){
            prompt = prompt + " Here is some text that introduces the npc, check for a name to use. '"+settings['details'].replace(/[^\w\s]/gi, '')+"'"
        }
     if('succeed' in settings){
           prompt = prompt+ " The NPC has a reason they want the character to succeed. This is info about the character to help write a reason. "+settings['palyer_background']
        }else{
            prompt = prompt+ " The NPC has a reason they want the character to fail. This is info about the character to help write a reason. "+settings['player_background']
    }
    Object.keys(fillers).forEach(key => {
        // console.log(`${key}: ${settings[key]}`);
        if( typeof(settings[key])!=='undefined' && settings[key]!=''){
            //fill in anything not generated.
            prompt = prompt+' Their '+key+' is '+settings[key]+' .'
        }else{
            prompt = prompt+fillers[key]
        }
    });
    
    // console.log(prompt);
    // 
    try{
        var response = await fetchIdeasObjectSchema(prompt,general_response_schema)
    }catch{
        console.log('failed')
         prompt = prompt+' : {"name":"","appearance":"","desires":"","personality":"","weakness":"","background":"","hates":"","skills":[],"reason":"","relationship":""} '+' fill in the json object with the relevant text and respond.﻿﻿'
        // const NPCschema = {"name":"","appearance":"","desires":"","personality":"","weakness":"","background":"","hates":"","skills":[],"reason":""}
        response = await fetchIdeasObject(prompt)
    }

    console.log(response)
    try{
        response['class'] = npc_class;
        response['active'] = true
        response['lore'] = 1;
        response['src'] = NextImage('random');
    }catch{
        console.log('failed to generate')
    }
    return response
}

async function companion (settings) {
    console.log('companion')
    console.log(settings)
    const classes = ['Alcehmist','Barbarian','Champion','Cleric','Druid','Fighter','Monk','Ranger','Rouge','Sorcer','Summoner','Witch','Wizard']
    var companion_class = classes[Math.floor(Math.random()*classes.length)];
    const prompt = 'Your task is to create an npc human companion for a character named '+settings['name']+'. '+
        "The npc's class is "+companion_class+"."+
        "\n The npc should have a first and last name."+
        "\n The npc's background should have something to do with an occupation and their family., "+
        "\n The npc's weakness should be something a character could use against them such as an element like fire, water, or. "+
        "\n The npc's desire should be a concrete desire or tangible goal for the npc to work towards. For example: gold, acquisition of an item, the death of someone who wronged them. "+
        "\n Also there should be a reason the npc wants the character to succeed. This is info about the character to help write a reason."+settings['background']+
        '\n: {"name":"","appearance":"","desires":"","personality":"","weakness":"","background":"","hates":"","skills":[],"reasonWantsCharactertoSucceed":""} '+
        'fill in the json object with the relevant text and respond.﻿﻿'
    console.log(prompt);
    var response = await fetchIdeasObject(prompt);
    try{
        response['class'] = companion_class;
        response['lore'] = 1;
        response['status'] = 5;
    }catch{
        console.log(prompt);
        response = ''
    }
    
    return response
}

async function nemisis (settings) {
    console.log('companion')
    console.log(settings)
    const classes = ['Alcehmist','Barbarian','Champion','Cleric','Druid','Fighter','Monk','Ranger','Rouge','Sorcer','Summoner','Witch','Wizard']
    var nemisis_class = classes[Math.floor(Math.random()*classes.length)];
    const prompt = 'Your task is to create an npc human nemisis for a character named '+settings['name']+'.'+
        "The npc's class is "+nemisis_class+"."+
        "\n The npc should have a first and last name."+
        "\n The npc's background should have something to do with an occupation and their family., "+
        "\n The npc's weakness should be something a character could use against them such as an element like fire, water, or. "+
        "\n The npc's desire should be a concrete desire or tangible goal for the npc to work towards. For example: gold, acquisition of an item, the death of someone who wronged them. "+
        "\n Also there should be a reason the npc wants the character to fail. This is info about the character to help write a reason."+settings['background']+
        '\n: {"name":"","appearance":"","desires":"","personality":"","weakness":"","background":"","hates":"","skills":[],"reasonWantsCharactertoFail":""} '+
        'fill in the json object with the relevant text and respond.﻿﻿'
    console.log(prompt);
    var response = await fetchIdeasObject(prompt);
    try{
        response['class'] = nemisis_class;
        response['lore'] = 1;
        response['status'] = -6;
    }catch{
        console.log('failed to load nemisis')
    }
    return response
}

async function creatures (settings) {
    console.log('creature')
    console.log(settings)
    const creature_types =[
            "Dragon",
            "Elf",
            "Dwarf",
            "Orc",
            "Goblin",
            "Troll",
            "Undead",
            "Elemental",
            "Giant",
            "Demon",
            "Angel",
            "Fey",
            "Beast",
            "Construct",
            "Celestial",
            "Minotaur",
            "Gnoll",
            "Centaur",
            "Sphinx",
            "Hydra"
          ];
    // const jsonSchema = {"name":"","creature_type":"Orc","appearance":"","desires":"","personality":"","weakness":"","background":"","hates":"","skills":[{}],"creatureWillRunAway":""}
    const response_schema = {
  "type": "object",
  "properties": {
    "name": {
      "type": "string",
      "description": "A descriptive name of the creature"
    },
    "creature_type": {
      "type": "string",
      "description": "The type of creature (e.g., Orc, Goblin, Dragon)"
    },
    "appearance": {
      "type": "string",
      "description": "A description of the creature's physical appearance"
    },
    "desires": {
      "type": "string",
      "description": "The creature's goals, motivations, or desires"
    },
    "personality": {
      "type": "string",
      "description": "A description of the creature's personality traits"
    },
    "weakness": {
      "type": "string",
      "description": "The creature's vulnerabilities or weaknesses"
    },
    "background": {
      "type": "string",
      "description": "The creature's backstory or history"
    },
    "hates": {
      "type": "string",
      "description": "Things the creature dislikes or hates"
    },
    "skills": {
      "type": "array",
      "items": {
        "type": "object",
        "properties": {
          "name": {
            "type": "string",
            "description": "The name of the skill"
          },
          "level": {
            "type": "integer",
            "description": "The creature's proficiency level in the skill"
          }
        },
        "required": [
          "name",
          "level"
        ]
      }
    },
    "creatureWillRunAway": {
      "type": "boolean",
      "description": "Whether the creature is likely to flee from combat"
    }
  },
  "required": [
    "name",
    "appearance",
    "hates"
  ]
}
    var creature_type = creature_types[Math.floor(Math.random()*creature_types.length)];

    var prompt = 'Your task is to create a fantasy creature. Its name should be descriptive.'
        if('type' in settings){
        creature_type = settings['type']
        prompt = prompt+" The creature's type is "+creature_type+". "
        }else{
        prompt = prompt+" The creature can have types "+creature_types.toString()+"."
        }
        if('rumor' in settings){
            prompt = prompt+ " Here is a rumor that the character knows. "+settings['rumor']
        }
        if('details' in settings){
            prompt = prompt+ " Here is information about the creature from the current scene where it was introduced. "+settings['details']
        }
        prompt = prompt + " The creature's background should have something to do with how it came to this location.  "+
        " The creature's weakness should be something a character could use against them such as an element like fire, water, or. \n"+
        " The creature's desire should be a concrete desire or tangible goal for the creature to work towards. For example: defending its nest, revenge against a type of character who hurt it or collecting gold. "+
        "The creature should have a condition where it would run away in creatureWillRunAway."
        // 'Follow JSON schema. <JSONSchema>'+JSON.stringify(jsonSchema)+'</JSONSchema>'
    console.log(prompt);
    var response = await fetchIdeasObjectSchema(prompt, response_schema);
    console.log(response)
    try{
        response['type'] = creature_type;
        response['lore'] = 1;
        response['status'] = -6;
        response['active'] = true;
    }catch{
        response=''
    }
    return response
}

async function character (settings) {
    console.log('character')
    console.log(settings)
    const classes = ['Alcehmist','Barbarian','Champion','Cleric','Druid','Fighter','Monk','Ranger','Rouge','Sorcer','Summoner','Witch','Wizard']
    var character_class = classes[Math.floor(Math.random()*classes.length)];
    const houses = ['Proia','High Order of Warlocks']
    var character_houses = houses[Math.floor(Math.random()*houses.length)];
    var fillers = {
        name:'\nTheir Name should be first and last.',
      class: "\nThere character's class is "+character_class,
      description: "\nShould have something to do with their eyes, armor, how they carry themselves. ",
      epic_motivation: "\nTheir Epic Motivation should be something extrinsic such as personal wealth, adventuring, helping a friend or family and have something to do with a faction. ",
      pateron: '\nThey also may or may not have a pateron in their family with a certain faction from '+character_houses+' .',
      trivial_motivation: '\nTheir Trivial Motivation should be improving or learning a hobby or activity. ',
      background: '\nTheir background should have something to do with an occupation and their family. ',
      hates: '\nThings they hate should be specific and not related to their occupation. ',
      skills: '\nThey should have three skills that match their class or background.'
    }
    var prompt = 'Your task is to create a human character for a unique Magical world.'
    Object.keys(settings).forEach(key => {
        console.log(`${key}: ${settings[key]}`);
        if( typeof(settings[key])!=='undefined' && settings[key]!=''){
            //fill in anything not generated.
            prompt = prompt+' Their '+key+' is '+settings[key]+' .'
        }else{
            prompt = prompt+fillers[key]
        }
    });
    prompt = prompt+': {"name":"","description":"","epic_motivation":"","trivial_motivation":"","background":"","pateron":"",hates":"","skills":[{"name":"","lore":1,"description":""}]} '+
        'fill in the json object with the relevant text and respond.﻿﻿'
    console.log(prompt)
    try{ 
    var response = await fetchIdeasObject(prompt)
    response['class'] = character_class;
    console.log(response)
    }catch{
        response = ''
    }
    return response
}

async function factions (settings) {
    console.log('Creat Faction')
    console.log(settings)
    const faction_types = ['Royal House', 'Crime', 'Religious','Secret','Mercenaries', 'Cult']
    var faction_type = faction_types[Math.floor(Math.random()*faction_types.length)];
    const houses = ['Proia','High Order of Warlocks']
    var character_houses = houses[Math.floor(Math.random()*houses.length)];

    const prompt = 'Your task is to create a faction for a unique Magical world.'+
    "The faction type is  "+faction_type+
    '.  Their Name should be short but descriptive.'+
    '.  Their Main Motivation should be something extrinsic such as personal wealth, adventuring, helping a friend or family and have something to do with a faction.'+
    '.  They also may or may not have a pateron in their family with a certain faction from '+character_houses+' .'+
    '.  They have a leader.'+ 
    'Their background should have something to do with what their goal is and what they want to accomplish.'+
    'Things they hate should be specific to their goals and main motivation.'+
    'Skills would be common or special skills that individuals in this group might posses.'+
        ': {"name":"","description":"","main_motivation":"","leader":"","background":"",hates":"","skills":[]} '+
        'fill in the json object with the relevant text and respond.﻿﻿'
    console.log(prompt)
    try{ 
    var response = await fetchIdeasObject(prompt)
    response['type'] = faction_type;
    response['lore'] = 1;
    response['status'] = 0;
    console.log(response)
    }catch{
        response = ''
    }
    return response
}

async function items (settings) {
    console.log('Create Item')
    console.log(settings)
    const item_types = ['Magical','Mythical','Cursed','Heirloom']
    var item_type = item_types[Math.floor(Math.random()*item_types.length)];
    const houses = ['Proia','High Order of Warlocks']
    var character_houses = houses[Math.floor(Math.random()*houses.length)];

    var prompt = 'Your task is to create an Item for a unique Magical world.'
    if('name' in settings){
        prompt = prompt+ 'The Item name is '+settings['name']+' .'
    }else{
        prompt = prompt+'The Item name should be short and descriptive.'
    }
    prompt = prompt+"The item type is  "+item_type+
    '.  Description should be a short description of its history. If it is a Heirloom add a character from this house, '+character_houses+
    '.  The Items last owner should be a short description of that person and what they were using it for. '+
    '.  The Items twist should be something horrible, odd, or unexpected that happens if the item is missed used or faulty.'+
        ': {"name":"","description":"","twist":"","last_owner":""} '+
        'fill in the json object with the relevant text and respond.﻿﻿'
    console.log(prompt)
    try{ 
    var response = await fetchIdeasObject(prompt)
    response['type'] = item_type;
    response['lore'] = 1;
    response['status'] = 0;
    console.log(response)
    }catch{
        response = ''
    }
    return response
}

async function skills (settings) {
    console.log('Create Skill')
    console.log(settings)
    const item_types = ['Magical','Mythical','Cursed','Legacy']
    var item_type = item_types[Math.floor(Math.random()*item_types.length)];
    const houses = ['Proia','High Order of Warlocks', 'Black Legion of Ballmer']
    var character_houses = houses[Math.floor(Math.random()*houses.length)];

    var prompt = 'Your task is to create an Skill for a unique Magical world.'
    if('name' in settings){
        prompt = prompt+ ' The Skill name is '+settings['name']+' .'
    }else{
        prompt = prompt+' The Skill name should be short and descriptive.'
    }
    prompt = prompt+" The skill type is  "+item_type+
    '.  Description should be a short description of the skills history. If it is a Legacy add a character from this house, '+character_houses+
    '.  The Skills master user should be a short description of that person and what they were using it for. '+
    '.  The Skills twist should be something horrible, odd, or unexpected that happens if the skill is missed used or faulty.'+
        ': {"name":"","description":"","twist":"","master_user":""} '+
        'fill in the json object with the relevant text and respond.﻿﻿'
    console.log(prompt)
    try{ 
    var response = await fetchIdeasObject(prompt)
    response['type'] = item_type;
    response['lore'] = 1;
    response['status'] = 0;
    console.log(response)
    }catch{
        response = ''
    }
    return response
}

async function generate_guide(settings) {
    console.log('guide text')
    console.log(settings)
    var prompt = 'You are an NPC in a fantasy game, the npc is '+settings['npc']['name']+'\n'+
     ' The npcs background is: '+settings['npc']['background']+'\n'+
     ' The npcs motivation is: '+settings['npc']['reasonWantsCharactertoSucceed']+'\n'+
     'The scene motivation is: '+settings['current_rumor']+'\n'+
    'The main character has this motivation: '+settings['motivation']+'.\n'+
    'This is the creature in the scene: '+settings['active_creature']+
    '\n This is the last comments in the scene: '+settings['context']+
        "\n Write short specific text hint in the voice of the NPC that helps guide the character to a goal they can acomplish in the scene."+': {"hint":""} '+
        '\n Fill in the json object with the relevant text and respond.﻿﻿'
    console.log(prompt);
    var response = await fetchIdeasObject(prompt)
    console.log(response)
    return response['hint']
}

async function generate_encounter_result(settings) {
    console.log('encounter result')
    console.log(settings)
    var prompt = 'You are a GM in a fantasy rpg.'+
        'A charcter named '+ settings['character_name']+' has attemoted a role.'+
        'The result is they '+settings['result_text']+' this attempt, '+settings['attempt']+''+
        "Due to this roll "+settings['impacted']['name']+' which is a '+settings['impacted']['type']+' has been impacted.'+
        " Write a short two sentance twist result that will impact the player."+
         ': {"result":""} '+
        'fill in the json object with the relevant text and respond.﻿﻿'
    console.log(prompt);
    
    try{ 
        var response = await fetchIdeasObject(prompt);
        console.log(response)
    }catch{
        response = ''
    }
    return response
}

export {
    goals, rumors, locations, npcs , character, companion, generate_guide, nemisis, creatures, factions, items, generate_encounter_result, fetchIdeasObject, skills
}