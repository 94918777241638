<template> 
  <div class='row justify-content-md-center'>
 <div class="col-xs-12 col-md-6">
    <div class='card' style="margin-bottom: 60px;">
        <div class="card-body">
    <h4 style="margin-top: 20px;">Adventure</h4>
    <div class="post_top white-background"><div class="image-box" style="max-height: 240px; overflow: hidden;">
    <img v-if="adventure && adventure.image_src" class="" style="!position: absolute; height:600px;" :src="adventure.image_src" alt="Card image cap">
  </div>
</div>
      <!-- <img v-else class="card-image-marquee-create" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png" alt="Card image cap"> -->
      <div class="card-body" style="">
        <h5 v-if="adventure && adventure.title">{{adventure.title}}</h5>
        <!-- <h5 v-else class="card-title">Our hero</h5> -->
        <!-- <p v-if="adventure" class="card-text" style="font-size: 14px; text-align: left;">{{adventure.description}}</p> -->
        <p v-if="adventure" class="card-text" style="font-size: 14px; text-align: left;" v-html="adventure.description"></p>
        <div  v-for="(goal) in adventure.goals" v-bind:key='goal' >
              <i :class="'ra '+tagLogo(goal.type)"></i> {{goal.description}}
          </div>
  </div>
</div>
</div>
    </div>
</div>
<h3>Other Adventurers</h3>
<div class='row justify-content-md-center'>
        <!-- <div class="col-xs-12 col-md-6 card-group"> -->
        <div v-for="event in event_list" v-bind:key='event' class="col-xs-4 col-md-4">
          <div  class="card character-card" >
            <img style="width:100%;" :src="eventImage(event.tilt)">
            <!-- <img class="card-img-top" src="/" alt="Card image cap"> -->
            <!-- <img :src="characterCurrentSceneImage(character)" alt="Card image cap" class="card-img-top" style="height:100%;"> -->
            <div class="card-body" style=" background: rgba(2, 2, 1, 0.6); color: white;">
              <h5 class="card-title shimmer" style='margin-top:20px; text-align: left;'>
                <router-link :to="'/character/'+event.character_id"  target="_blank" style="color:white; text-decoration:none;">
                  <div class="small-image" style="float:left;">
                  <img v-if="event.character_src" class="" :src="event.character_src" :style="characterImage(event.character_src)">
                  <img v-else class="small-image" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png"></div>
                  <div style="float:left;">{{ event.character_name }}</div>
                </router-link>
              </h5>
              <!-- <span>{{ character.createdOn}}</span> -->
              <!-- <p>{{character.description}}</p> -->
              
              <div style="float:left; font-size:14px; clear: both; "><b>Goal:</b> {{ event.goal }}</div>
              <div style="width:100%;clear: both; padding: 1px 0 0 0;"><hr></div>
              <div class="quest-summary" ><p class="font-weight-normal" style="font-size: 14px;">{{ event.content }}</p></div>

              <span v-if="event.tilt" class="white">{{result_text(event.tilt)}} <i :class="'ra '+result_icon(event.tilt)"></i></span> 
              <p> 
                <router-link  :to="'/character/'+event.character_id+'?gm=true'" target="_blank" style="color:white; text-decoration:none;"><span class="oi oi-comment-square space-icon"></span> Read The Tale</router-link>
                <!-- <a :href="'character/'+character.id" style="color:white; text-decoration:none;"><span class="oi oi-comment-square space-icon"></span> Continue The Tale</a> -->
              </p>
            <!-- </div> -->
          </div>
        </div>
      </div>
  </div>
</template>


<script>
  const fb = require('../../firebaseConfig.js')
// { attributionControl:false }
    export default {
        components: {
        },
       props: {
        adventure_id: {
            type: String,
            default: () => ''
          }
       },
         data() {
            return {
                adventure: {},
                event_list: []
            }
            },
            mounted(){
              console.log(this.adventure_id)
              this.loadAdventure(this.adventure_id)
              this.loadEvents()
            },
              methods: {
                 characterImage(src){
                  if(!src.includes('?')){
                    return ''
                  }
      var response = '';
      var calc = {'y':0,'x':0,'height':0,'width':0}
      var pop = src.split('?')
      pop = pop[1]
      pop = pop.split('&')
      pop.forEach(function(pair) {
        var key = pair.split('=')[0];
        var value = pair.split('=')[1];
        calc[key] = value;
      });
      response = 'position:absolute; left:-'+calc['x']+'px; top:-'+calc['y']+'px; height:'+Math.round(calc['height'])+'px; width:'+Math.round(calc['width'])+'px;';
      return response
    },
              result_text: function(calculated_result){
        if(calculated_result < 6){
          return 'failed'
        }else if (calculated_result > 8){
          return 'success'
        }else if(calculated_result > 5){
          return 'partial success';
        }
        return calculated_result;
      },
       tagLogo(type){
      var logos = {
        'item':'ra-torch',
        'npc':'ra-helmet',
        'heart':'ra-two-hearts',
        'rumor':'ra-x-mark',
        'faction':'ra-crown',
        'creature':'ra-creature',
        'info':'ra-book',
      }
      return logos[type]
    },
      eventImage(result){
      var clean_type = this.result_text(result);
      console.log(clean_type)
      if(clean_type=='failed'){
        return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Scene%20Backgrounds/JokeFall.jpeg'
      }
      if(clean_type=='partial success'){
        return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Scene%20Backgrounds/PartialSuccess.jpeg'
      }
      if(clean_type=='success'){
        return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Scene%20Backgrounds/success.jpeg'
      }
    },
      result_icon: function(calculated_result){
        if(calculated_result < 6){
          return 'ra-falling'
        }else if (calculated_result > 8){
          return 'ra-muscle-fat'
        }else if(calculated_result > 5){
          return 'ra-player-shot';
        }
        return calculated_result;
      },
              loadAdventure: function(adventureID){
                  console.log('loading adventure '+adventureID)
                  fb.adventuresCollection.doc(adventureID).onSnapshot(snapshot=>{
                    if (snapshot.exists) {
                      // console.log('snapshot')
                      let adventure = snapshot.data();
                      adventure.id = snapshot.id;
                      this.adventure = adventure 
                      // console.log(adventure)
                      if(this.adventure.companion){
                        this.companionObject = this.adventure.companion
                      }
                      if(this.adventure.nemisis){
                        this.nemisisObject = this.adventure.nemisis
                      }
                      console.log(this.adventure)
                    } else {
                            // snapshot.data() will be undefined in this case
                            console.log("No such document!");
                          }  
                        })
                },
                loadEvents: function(){
                // var user = this.currentUser
                console.log(this.adventure_id)
      fb.activityCollection.where('adventure_id','==',this.adventure_id).orderBy('createdOn', 'desc').limit(10).onSnapshot(querySnapshot => {
              let eventsArray = []

              querySnapshot.forEach(doc => {
                  let event= doc.data()
                  console.log('here')
                  console.log(event)
                  event.id = doc.id
                  eventsArray.push(event)
              })
              this.event_list  = eventsArray
              console.log(this.event_list)
              // this.store.commit('setCharacters', charactersArray)
      });
    }
                
        }
      }
</script>